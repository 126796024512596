import { Button, Checkbox, Divider, Grid, Loading, Modal, Select, Tabs } from "@geist-ui/core";
import { useState } from "react";
import { API } from "../helpers/api";
import { Time } from "../helpers/time";
import { useStore } from "../stores/main";
import DatePicker from "./DatePicker";

export default function ExportDetails(props) {

  const reportType = props.report || "cost_center";
  const [policyFilter, setPolicyFilter] = useState("include")
  const [statusFilter, setStatusFilter] = useState(["open", "confirmed"])
  const [bookingType, setBookingType] = useState(["already_booked", "new_flights"])
  const [timeFilterMode, setTimeFilterMode] = useState("trip_end")
  const [timepickerMode, setTimePickerMode] = useState("by_months")
  const [timepickerMonth, setTimePickerMonth] = useState("0")
  const [timepickerYear, setTimePickerYear] = useState((new Date()).getFullYear().toString())
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false)
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const company = useStore((store) => store.company)

  let supportsPolicies = (company && company.feature_policies) ? true : false

  const formVerification = () => {
    var errors = [];
    if (statusFilter.length === 0) {
      errors.push("Select at least one status.")
    }
    if (bookingType.length === 0) {
      errors.push("Select at least one booking type.")
    }
    return errors;
  }


  const prepareFilterPayload = () => {

    var start_date = new Date();
    var end_date = new Date();

    if (timepickerMode === "by_months") {
      var month = pastFewMonths()[parseInt(timepickerMonth)];
      start_date = new Date(Date.UTC(month.year, month.month, 1, 0, 0, 0, 0))
      end_date = new Date(Date.UTC(month.year, month.month+1, 0, 23, 59, 59, 999))
    }
    if (timepickerMode === "by_year") {
      start_date = new Date(Date.UTC(parseInt(timepickerYear, 10), 0, 1, 0, 0, 0, 0))
      end_date = new Date(Date.UTC(parseInt(timepickerYear, 10), 11, 31, 23, 59, 59, 999)) // JS: 11 = Dec
    }
    if (timepickerMode === "timeframe") {
      start_date = Time.shortFormatDateToFullDate(startDate);
      end_date = Time.shortFormatDateToFullDate(endDate);
      // Go to next day, then back by 1s
      end_date.setUTCDate(end_date.getUTCDate() + 1)
      end_date.setUTCMilliseconds(end_date.getUTCMilliseconds() - 1000)
    }

    return {
      "report_type": reportType,
      "status": statusFilter,
      "booking_type": bookingType,
      "policy_violations": policyFilter,
      "start_date": Math.round(start_date.getTime() / 1000),
      "end_date": Math.round(end_date.getTime() / 1000),
      "filter_mode": timeFilterMode
    }
  }

  const pastFewMonths = () => {
    var list = [];
    var monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var d = new Date();
    d.setDate(1);
    for (var i=0; i<=100; i++) {
      if (d.getFullYear() < 2022) continue;
      list.push({
        label: `${monthName[d.getMonth()]} ${d.getFullYear()}`,
        month: d.getMonth(), // JS: 0 = January
        year: d.getFullYear()
      })
      d.setMonth(d.getMonth() - 1);
    }
    return list
  }

  const pastFewYears = () => {
    var d = new Date();
    d.setDate(1);
    return [d.getFullYear(), d.getFullYear()-1, d.getFullYear()-2, d.getFullYear()-3, d.getFullYear()-4]
  }

  return (
    <>
      <Modal disableBackdropClick={true} visible={isLoading}>
        <div style={{fontWeight:"bold", fontSize:16}}>Generating the Report</div>
        <div style={{fontSize:12, color:"#999"}}>This can take up to a minute...</div>
        <br/>
        <Loading/>
      </Modal>

      <div style={{paddingTop:20}}>
    
        <div style={{fontWeight:"bold", fontSize: 18, marginBottom:30}}>Please specify the type of trip you want to include in your report</div>

        <Grid.Container style={{marginBottom:20}}>
          <Grid xs={6}>Status</Grid>
          <Grid xs={18}>
          <Checkbox.Group value={statusFilter} onChange={(e)=>{ setStatusFilter(e) }}>
            <Checkbox value="open">Open</Checkbox>
            <Checkbox value="confirmed">Confirmed</Checkbox>
            { reportType === "cost_center" &&
            <Checkbox value="deleted">Deleted</Checkbox>
            }            
          </Checkbox.Group>      
          </Grid>
        </Grid.Container>

        { supportsPolicies && 
        <Grid.Container style={{marginBottom:20}}>
          <Grid xs={6}>Travel Policy</Grid>
          <Grid xs={18}>
          <Select  style={{width:200}} placeholder="Include Out of Policy Trips" type="default" value={policyFilter} onChange={(v) => { setPolicyFilter(v) }}>
            <Select.Option value="include">Include Out of Policy Trips</Select.Option>
            <Select.Option value="only">Only Out of Policy Trips</Select.Option>
          </Select>
          </Grid>
        </Grid.Container>
        }   

        <Grid.Container style={{marginBottom:20}}>
          <Grid xs={6}>Type of Booking</Grid>
          <Grid xs={18}>
          <Checkbox.Group value={bookingType} onChange={(e)=>{ setBookingType(e) }}>
            <Checkbox value="already_booked">Already booked</Checkbox>
            <Checkbox value="new_flights">New flights</Checkbox>
          </Checkbox.Group>
          </Grid>
        </Grid.Container>

        <Divider/>

        <Grid.Container style={{marginBottom:15, marginTop:25}}>
          <Grid xs={6}>Date filtered by</Grid>
          <Grid xs={18}>
            <div> 
              <Select width="200px" initialValue={timeFilterMode} onChange={(e) => { setTimeFilterMode(e) }}>
                <Select.Option value={"trip_start"}>Trip Start</Select.Option>
                <Select.Option value={"trip_end"}>Trip End</Select.Option>
                <Select.Option value={"trip_created"}>Trip Created</Select.Option>
                <Select.Option value={"cost_center_change"}>Last Cost Center Change</Select.Option>
                <Select.Option value={"status_change"}>Last Status Change</Select.Option>
              </Select>
            </div>
          </Grid>
        </Grid.Container>

        <Grid.Container style={{marginBottom:20}}>
          <Grid xs={6}>Timeframe</Grid>
          <Grid xs={18}>
            <div style={{paddingBottom:20}}> 

              <Tabs initialValue="by_months" value={timepickerMode} onChange={(e) => { setTimePickerMode(e) }}>
                <Tabs.Item label="By Month" value="by_months"></Tabs.Item>
                <Tabs.Item label="By Year" value="by_year"></Tabs.Item>
                <Tabs.Item label="Specific" value="timeframe"></Tabs.Item>
              </Tabs>

              {timepickerMode === "by_months" && 
              <div style={{marginTop:15}}>
                <Select placeholder="A specific month" width="200px" initialValue={timepickerMonth} onChange={(e) => { setTimePickerMonth(e) }}>
                  {
                    pastFewMonths().map((m,i) => {
                      return <Select.Option key={`months_${i}`} value={i.toString()}>{m.label}</Select.Option>
                    })
                  }
                </Select>
              </div>
              }
              {timepickerMode === "by_year" && 
              <div style={{marginTop:15}}>
                <Select placeholder="A specific year" width="200px" initialValue={timepickerYear} onChange={(e) => { setTimePickerYear(e) }}>
                  {
                    pastFewYears().map((m) => {
                      return <Select.Option key={`year_${m}`} value={m.toString()}>{m.toString()}</Select.Option>
                    })
                  }
                </Select>
              </div>
              }
              {timepickerMode === "timeframe" && 
              <div style={{display:"flex", marginTop:15}}>
                <DatePicker
                  id="start_date"
                  onBlur={() => {
                    setStartDatePickerOpen(false)
                  }}
                  isOpen={startDatePickerOpen}
                  whenOpened={() => {
                    setStartDatePickerOpen(true)
                    setEndDatePickerOpen(false)
                  }}
                  whenClosed={() => {
                    setStartDatePickerOpen(false)
                  }}
                  label="From"
                  date={startDate}
                  minimumDate={
                    //Time.getDateByDayOffset(new Date(), -365 * 2)
                    new Date(2022, 9, 1)
                  }
                  maximumDate={new Date()}
                  onChange={(newDate) => {
                    /* 
                        Important: If the end date is EARLIER than this new start date, 
                        then we'll need to move the end date forward to the same day at the very least
                      */
                    if (endDate && endDate < newDate) { // Ends before it starts?
                      if (startDate !== newDate) { // New start?
                        setStartDate(newDate)
                        setEndDate(newDate) // Move end forward
                      }
                    } else {
                      setStartDate(newDate)
                    }
                  }}              
                />
                &nbsp; &nbsp;
                <DatePicker
                  id="end_date"
                  onBlur={() => {
                    setEndDatePickerOpen(false)
                  }}
                  isOpen={endDatePickerOpen}
                  whenOpened={() => {
                    setStartDatePickerOpen(false)
                    setEndDatePickerOpen(true)
                  }}
                  whenClosed={() => {
                    setEndDatePickerOpen(false)
                  }}
                  label="To"
                  date={endDate}
                  minimumDate={
                    Time.getDateByDayOffset(new Date(), -365 * 2)
                  }
                  maximumDate={new Date()}
                  onChange={(newDate) => {
                    if (endDate !== newDate) {
                      setEndDate(newDate)
                    }
                  }}              
                />              
              </div>
              }

            </div>
          </Grid>
        </Grid.Container>

        { /*
        <Grid.Container style={{marginBottom:20}}>
          <Grid xs={6}>Cost Centers</Grid>
          <Grid xs={18}>
            <div>
              <Button type="warning" ghost>Add Cost Centers</Button>
              <br/>
              <br/>
              All cost centers will be included.
            </div>
          </Grid>
        </Grid.Container>
        */
        }

        <Divider />

        <div style={{marginTop:30}}>
          <Button disabled={isLoading} scale={1.5} type="success" onClick={() => {

            let errors = formVerification();
            if (errors.length !== 0) {
              alert("Unable to generate a report:\n\n" + errors.join("\n"));
              return;
            }
            let filterPayload = prepareFilterPayload()
            setIsLoading(true)
            API.downloadReport(filterPayload)
            .then((response) => {
              console.log(response)
            }).catch((error) => {

            }).finally(() => {
              setIsLoading(false)
            })
          }}>Download Report</Button>
        </div>
      </div>
    </>
  )
}